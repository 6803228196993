<template>
	<div :class="['bottom-menu',isIndex?'bottom-menu-bar-index':'']" v-if="!menuLoading">
		<div class="mask" v-if="$store.state.isWallet" @click.stop="tabMask"></div>
		<div class="bottom-menu__item">
			<button class="menu-link" @click="menuClick">
				<svgIcon icon="icon-hamburger-default" className="tabbaricon" widthName="28" heightName="28"></svgIcon>
				<span class="menu-link__label">{{ $t('菜单') }}</span>
			</button>
		</div>
		<div class="bottom-menu__item">
			<router-link to="/activity-hall" class="menu-link">
				<div class="tabbaricon">
					<img src="@/assets/images/nav3.png" width="20" height="20" />
				</div>
				<span class="menu-link__label">{{$t("活动厅")}}</span>
			</router-link>
		</div>
		<div class="bottom-menu__item" @click.stop="to('/all-game')">
			<div class="menu-link">
				<div class="wallet-box">
					<div :class="['wallet-wapper', 'wallet-wapper-active']">
						<div class="wallet-icon">
							<img src="@/assets/images/tabbar-game.png" width="20" height="20" />
						</div>
					</div>
				</div>
				<span class="menu-link__label">{{ $t('所有游戏') }}</span>
			</div>
		</div>
		<div class="bottom-menu__item">
			<router-link to="/rank-system" class="menu-link">
				<div class="tabbaricon">
					<img src="@/assets/images/tabbar-vip.png" width="20" height="20" />
				</div>
				<span class="menu-link__label">{{$t("VIP")}}</span>
			</router-link>
		</div>
		<div class="bottom-menu__item">
			<router-link to="/team-center" class="menu-link">
				<div class="item_icon"><i class="iconfont icon-share"></i></div>
				<span class="menu-link__label">{{ $t('团队中心') }}</span>
			</router-link>
		</div>
	</div>
</template>

<script>
	import svgIcon from '@/components/svg.vue'
	import {url_menu} from '@/api/url.js'
	export default {
		components: {
			svgIcon
		},
		name: 'Foot',
		data() {
			return {
				visible:false,
				tabscur:0,
				isIndex:false,
				timeId:null,
				menuLoading:false
			};
		},
		watch:{
			"$store.state.isWallet":function(e){
				clearTimeout(this.timeId)
				if(e){
					this.isIndex = e
				}else{
					this.timeId = setTimeout(()=>{
						this.isIndex = e
					},200)
				}
			}
		},
		async mounted(){
			await this.getMenu()
		},
		computed:{
			menuList(){
				return this.$store.state.menuList
			}
		},
		beforeDestroy(){
			this.$store.commit("setIsWallet",false)
		},
		methods: {
			to(url){
				if(url.startsWith('http')){
					location.href = url
				}else{
					this.$router.push(url)
				}
			},
			async getMenu(){
				if(this.menuList!==null) return;
				this.menuLoading = true
				const {data} = await url_menu()
				this.menuLoading = false
				if(data.code==1){
					if(data.data.length==2){
						this.$store.commit("menuList",data.data)
					}else{
						this.$store.commit("menuList",false)
					}
				}
			},
			tabMask(){
				if(this.$store.state.loading.wallet) return
				if(!this.$store.state.loading.wallet){
					this.$store.state.isWallet=false
				}
			},
			/**
			 * 显示钱包
			 * */ 
			showWallet(){
				if(!this.$store.state.isLoginStatu){
					this.showLoginReg(0)
					return
				}
				if(this.$store.state.loading.wallet) return
				this.$store.commit("setIsWallet",!this.$store.state.isWallet)
				if(this.$store.state.isWallet) this.$store.state.walletGetData++
			},
			menuClick(){
				this.$emit('menuClick')
			},
		},
	}
</script>
<style scoped>
	.tabbaricon{
		width:28px;
		height:28px;
		display:flex;
		align-items: center;
		justify-content: center;
	}
	.bottom-menu {
		z-index: 304;
		position: fixed;
		bottom: 0;
		width: 100%;
    height: 60px;
		background-color: #161f2c;
		display: none;
		grid-template-columns: repeat(auto-fit, minmax(40px, 1fr));
		grid-column-gap: 4px;
		padding-left: 24px;
		padding-right: 24px;
		padding-bottom: 8px;
		border-top: 1px solid rgba(183, 207, 255, .0509803922);
	}
	.bottom-menu-bar-index{
		z-index: 99999;
	}
	@media(max-width:768px) {
		.bottom-menu {
			display: grid;
			padding-left: 16px;
			padding-right: 16px;
      height: 60px;
      height: calc(60px + constant(safe-area-inset-bottom));
      height: calc(60px + env(safe-area-inset-bottom));
		}
	}

	.bottom-menu__item {
		position: relative;
		height: 52px
	}

	.menu-link {
		position: relative;
		width: 100%;
		text-decoration: none;
		color: #93acd3;
		justify-content: center;
    text-align: center;
		height: 100%;
		transform: translateZ(0)
	}

	.menu-link,
	.menu-link--tooltip {
		display: flex;
		flex-direction: column;
		align-items: center
	}

	.menu-link--tooltip:hover {
		color: #fff
	}

	.menu-link--tooltip:hover>svg {
		fill: #fff
	}

	.menu-link--tooltip:hover:hover {
		color: #fff
	}

	.menu-link:before {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		opacity: 0;
		top: -15px;
		left: 50%;
		transform: translate(-50%);
		background: transparent;
		filter: blur(0);
		transition: all .2s ease-in-out;
		z-index: -1
	}

	.menu-link svg {
		fill: #93acd3;
		transition: fill .1s ease-in-out
	}

	.menu-link__label {
		font-weight: 600;
		font-size: 9px;
		line-height: 12px;
		transition: color .1s ease-in-out
	}

	.menu-link:hover {
		color: #93acd3
	}

	.menu-link--active {
		color: #fff
	}

	.menu-link--active>svg {
		fill: #fff
	}

	.menu-link--active:hover {
		color: #fff
	}

	.menu-link--active:before {
		content: "";
		position: absolute;
		width: 30px;
		height: 30px;
		top: -15px;
		left: 50%;
		transform: translate(-50%);
		background: #2283f6;
		filter: blur(15px);
		opacity: 1;
		transition: all .2s ease-in-out;
		z-index: -1
	}

	.menu-link__coin {
		position: absolute;
		top: 4px;
		left: 50%;
		transform: translate(-50%) scale(1);
		width: 16px;
		height: 16px;
		-webkit-animation: pulse-data-v-ce6ef5ae .7s linear infinite;
		animation: pulse-data-v-ce6ef5ae .7s linear infinite
	}

	.menu-link__coin--static {
		-webkit-animation: none;
		animation: none;
		transform: translate(0) scale(1)
	}

	.amount {
		min-width: 20px;
		height: 20px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-left: 5px;
		padding-right: 5px;
		background: #55657e;
		border-radius: 50px;
		border: 1px solid #161f2c;
		position: absolute;
		top: 1px;
		left: 48%;
		font-weight: 700;
		font-size: 10px;
		line-height: 8px;
		color: #fff
	}

	.amount--tag,
	.is-bonuses {
		background-color: #1bb83d
	}

	@-webkit-keyframes pulse-data-v-ce6ef5ae {
		0% {
			transform: scale(1)
		}

		50% {
			transform: scale(1.2)
		}

		to {
			transform: scale(1)
		}
	}

	@keyframes pulse-data-v-ce6ef5ae {
		0% {
			transform: scale(1)
		}

		50% {
			transform: scale(1.2)
		}

		to {
			transform: scale(1)
		}
	}

	.contests-controls {
		position: fixed;
		right: 0;
		top: 112px;
		width: 40px;
		border-radius: 10px 0 0 10px;
		background-color: #202a39;
		z-index: 10;
		transition: right .2s ease-in-out;
		cursor: pointer;
		padding: 4px
	}

	@media(max-width:1000px) {
		.contests-controls {
			position: static;
			width: auto;
			padding: 6px
		}
	}

	.contests-controls--move {
		right: 340px
	}

	.contests-controls--move-short {
		right: 325px
	}

	.contests-controls__item {
		height: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all .2s;
		position: relative;
		border-radius: 7px
	}

	@media(max-width:1000px) {
		.contests-controls__item {
			flex-direction: row-reverse;
			justify-content: flex-end;
			padding: 6px 7.5px
		}
	}

	@media(max-width:1000px) {
		.contests-controls__item-img {
			width: 25px;
			height: 25px
		}
	}

	.contests-controls__item:not(.contests-controls__item--active):hover {
		background-color: #3c485c
	}

	.contests-controls__item:not(.contests-controls__item--active):hover .contests-controls__item-tip {
		display: block
	}

	.contests-controls__item+.contests-controls__item {
		margin-top: 4px
	}

	.contests-controls__item--active {
		background: #2283f6
	}

	.contests-controls__item--active .contests-controls__item-tip {
		color: #fff
	}

	.contests-controls__item-tip {
		display: none;
		position: absolute;
		width: 106px;
		height: 38px;
		background: #263041;
		border-radius: 8px;
		left: -124px;
		top: 50%;
		transform: translateY(-50%);
		padding-left: 12px;
		line-height: 38px;
		font-weight: 500;
		font-size: 12px;
		color: #93acd3
	}

	@media(max-width:1000px) {
		.contests-controls__item-tip {
			position: static;
			display: block;
			width: auto;
			height: auto;
			background: none;
			transform: none;
			line-height: 18px;
			font-size: 14px
		}
	}

	.contests-controls__item-tip:after {
		content: "";
		position: absolute;
		display: block;
		right: -6px;
		top: 0;
		width: 20px;
		height: 20px;
		background: #263041;
		transform: translateY(50%) rotate(135deg);
		border-radius: 2px;
		z-index: -1
	}

	@media(max-width:1000px) {
		.contests-controls__item-tip:after {
			display: none
		}
	}
	.wallet-box{
		width: 28px;
		height: 28px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.wallet-wapper{
		background-color: transparent;
		width: 28px;
		height: 28px;
		transition: all .5s;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;

	}
	.wallet-wapper-active{
		background-color: #ed1d49;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		transform: translateY(-60%) scale(1.8);
	}
	.wallet-icon{
		transition: all .5s;
		width: 16.6px;
		height: 16.6px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.wallet-wapper-active svg{
		transition: all .5s;
		fill: #FFF;
	}
	.mask{
		position: absolute;
		left: 0;
		top: 0;
		z-index: 999;
		height: 100%;
		width: 100%;
	}
	.item_icon{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 28px;
		height: 28px;
	}
	.item_icon .iconfont{
		font-size: 22px;
	}
</style>
